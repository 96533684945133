require('./src/styles/main.scss');
const { loadableReady } = require('@loadable/component');
const React = require('react');
const { SearchContextProvider } = require('./src/utils/context/searchContextProvider');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      // eslint-disable-next-line no-undef
      ReactDOM.hydrate(element, container, callback);
    });
  };
};

exports.wrapRootElement = ({ element }) => <SearchContextProvider>{element}</SearchContextProvider>;
