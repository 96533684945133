import React, { useState, createContext } from 'react';
import { navigate } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID!,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY!
);

export const SearchContext = createContext({
  query: '',
  searchHandler: (searchText, url) => ({ searchText, url }),
  algoliaClient,
} as PageContent.SearchContextType);

export const SearchContextProvider = ({ children }) => {
  const [query, setQuery] = useState('');

  const searchHandler = (searchText, url) => {
    setQuery(searchText);
    navigate(url);
  };

  return (
    <SearchContext.Provider value={{ query, searchHandler, algoliaClient }}>
      {children}
    </SearchContext.Provider>
  );
};
